.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spacer {
  aspect-ratio: 16 / 9;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer1 {
  background-image: url('./backgrounds/Vector.svg');
  background-repeat: repeat-x;
  background-size: auto 100%;
  animation: 15s linear 0s infinite wave-anim;
}

/*
  The animation is set to 1s, so the keyframes should be set to 0% and 100%.
  The background-position-x property is used to move the background image
  from left to right. The background-position-y property is used to move
  the background image from top to bottom.
*/

/* move the background from left to right, then switch the background image and use that for the next animation */
@keyframes wave-anim {
  from {
    background-position-x: 0%;
  }

  to {
    background-position-x: 100%;
  }
}

@keyframes heighten-home {
  from {
    /* pushes the sun down past the viewport */
    transform: scale(5%, 0%);
  }

  to {
    /* returns the sun to its default position */
    transform: scale(5%, 100%);
  }
}

@keyframes widen-home {
  from {
    /* pushes the sun down past the viewport */
    transform: scale(10%, 100%);
  }

  to {
    /* returns the sun to its default position */
    transform: scale(100%, 100%);
  }
}

.mdHome {
  flex-direction: row;
  text-align: left;
  align-items: center;
}

.mobileHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*
relative flex flex-col bg-black bg-opacity-10 text-black rounded-xl p-3 md:w-full
*/
.relevanceCard {
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 1000;
  outline: 2px solid rgba(165, 165, 165, 0.1);
  box-shadow: 2px 2px 5px rgba(165, 165, 165, 0.4);
  transition: scale(1) 0.3s ease-in-out;
}

/*
#projects-full.relevanceCard  {
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 1000;
  outline: 2px solid rgba(165, 165, 165, 0.1);
  box-shadow: 2px 2px 5px rgba(165, 165, 165, 0.4);
  transition: scale(1) 0.3s ease-in-out;
}

#projects-full.relevanceCard::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -100;
  opacity: 0.5;
}
*/

.relevanceCard:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.2);
  outline: 1px solid rgb(216, 112, 147);
  background-image: linear-gradient(145deg, rgba(255, 250, 250, 0.05), rgba(77, 77, 77, 0.05));
  border-radius: 0.5rem;
  box-shadow: 2px 2px 5px rgba(216, 112, 147, 0.4);
  transition: transform 0.1s ease-in-out, background-color 0.3s, background-image 1s, border-radius 0.3s;
}

.relevanceCardFull {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: 1rem;
}

/* text-inherit md:text-3xl */
.relevanceCard h1 {
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

/*
text-xs text-black bg-white border border-black p-1 px-2 rounded md:text-sm
*/
.relevanceTag {
  font-size: 0.75rem;
  /* bold text */
  font-weight: 700;
  color: black;
  background-color: white;
  border: 1px solid black;
  padding: 0.15rem 0.4rem;
  border-radius: 0.25rem;

  @media (min-width: 768px) {
    font-size: 0.85rem;
    line-height: 1.25rem;
  }
}

/* flex flex-col h-fit space-y-10 w-full py-20 items-center justify-center md:h-screen */
.pageSection {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    min-height: 100vh;
  }
}

.bg-logo {
  background-image: url(./assets/ProjectIcon.jpg);
  background-repeat: repeat;
  background-size: cover;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  font-weight: 600;
  color: white;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: palevioletred;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

/* when hover enters the element, scale the line up to the left */
.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom center;
}

/* w-10 h-10 hover:fill-pink-500 */
.nav-svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: white;
  transition: fill 0.25s ease-out;
}

.nav-svg:hover {
  fill: palevioletred;
  transition: fill 0.25s ease-out;
}

/* bg-black text-white w-fit p-1 px-2 rounded-md */
.projectLink {
  background-image: linear-gradient(145deg, rgba(255, 250, 250, 0.2), rgba(77, 77, 77, 0.5));
  color: white;
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding: 0.25rem 0.5rem;
  outline: 1px solid rgba(0, 0, 0, 1);
  border-radius: 0.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: .85rem;
}

/* absolute bottom-3 text-white text-center left-1/2 right-1/2 */
.scrollButton {
  font-weight: 600;
  position: absolute;
  bottom: 10px;
  color: white;
  text-align: center;
  left: 50%;
  right: 50%;
  text-shadow: 1px 1px 10px rgba(165, 165, 165, 1);
  fill: white;
  transition: color 0.25s ease-out, text-shadow 0.25s ease-out;
  animation: 3.5s linear 0s infinite lumen;
}

.scrollButton:hover {
  color: palevioletred;
  text-shadow: 1px 1px 10px palevioletred;
  transition: color 0.25s ease-out, text-shadow 0.25s ease-out;
}

@keyframes lumen {
  0% {
    color: white;
    fill: white;
    text-shadow: 1px 1px 10px rgba(165, 165, 165, 1);
    /* transition: color 0.25s ease-out, text-shadow 0.25s ease-out; */
  }
  50% {
    color: palevioletred;
    text-shadow: 1px 1px 10px palevioletred;
    /* transition: color 0.25s ease-out, text-shadow 0.25s ease-out; */
  }
  100% {
    color: white;
    fill: white;
    text-shadow: 1px 1px 10px rgba(165, 165, 165, 1);
    /* transition: color 0.25s ease-out, text-shadow 0.25s ease-out; */
  }
}